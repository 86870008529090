import axios, { AxiosInstance } from "axios";
import React, {
  createContext,
  useCallback,
  useContext,
  useState,
  useEffect,
} from "react";
import { useGlobal } from "./global";
import { modules } from "../utils/modules";
import { useNavigate } from "react-router-dom";
import { setUpConsultation } from "../services/generalServices";
import {
  getConsultLabel,
  getFranchiseLabel,
  getPersonLabel,
  getPropertyLabel,
  getProposalLabel,
} from "../utils/labels";
import {
  cityProps,
  franchiseProps,
  notificationsAttributes,
  proposal_status,
  ResultBiroProps,
  stateProps,
  userProps,
  userTypes,
} from "../interfaces";

interface UserInterface extends userProps {
  type: userTypes;
}

interface DataProps {
  token: string;
  user: UserInterface;
}

interface credentialsProps {
  email: string;
  password: string;
}

interface ISearchProps {
  search: string;
}

interface ISearchProposalsProps extends ISearchProps {
  status?: proposal_status;
  status_array?: proposal_status[];
}

interface ISearchPersonsProps extends ISearchProps {
  type?: "PF" | "PJ";
  person_types?: ("responsible" | "broker" | "analyst" | "manager")[];
  onlyCustomers?: boolean;
  removeIds?: string[];
}

interface ISearchConsultsProps extends ISearchProps {
  type_consult?: "PF" | "PJ";
  only_ok?: boolean;
}

interface ISearchCitiesProps extends ISearchProps {
  state_id?: string;
}

interface ISearchDocumentsTypesProps extends ISearchProps {
  type: "PF" | "PJ" | "property";
}

interface ISearchSignatoriesProps {
  property_id?: string;
  proposal_id?: string;
}

interface IConsultsServicesProps {
  property_type_id: (
    props: ISearchProps
  ) => Promise<{ data: any; formatted: any }>;
  consults: (
    props: ISearchConsultsProps
  ) => Promise<{ data: any; formatted: any }>;
  person_id: (
    props: ISearchPersonsProps
  ) => Promise<{ data: any; formatted: any }>;
  franchise_id: (props: ISearchProps) => Promise<{ data: any; formatted: any }>;
  state_id: (props: ISearchProps) => Promise<{ data: any; formatted: any }>;
  city_id: (
    props: ISearchCitiesProps
  ) => Promise<{ data: any; formatted: any }>;
  address: (
    props: ISearchProps
  ) => Promise<{ address?: any; city?: cityProps; state?: stateProps }>;
  document_type_id: (
    props: ISearchDocumentsTypesProps
  ) => Promise<{ data: any; formatted: any }>;
  signatories: (props: ISearchSignatoriesProps) => Promise<any>;
  contract_model_id: (
    props: ISearchProps
  ) => Promise<{ data: any; formatted: any }>;
  property_id: (props: ISearchProps) => Promise<{ data: any; formatted: any }>;
  proposal_id: (
    props: ISearchProposalsProps
  ) => Promise<{ data: any; formatted: any }>;
  default_id: (props: ISearchProps) => Promise<{ data: any; formatted: any }>;
  [key: string]: (
    props: any
  ) => Promise<
    | { address?: any; city?: cityProps; state?: stateProps }
    | { data: any; formatted: any }
  >;
}

interface ApiContextData {
  consultsServices: IConsultsServicesProps;
  api: AxiosInstance;
  user: UserInterface;
  token?: string;
  signIn(credentials: credentialsProps, remmemberMe?: boolean): Promise<void>;
  signOut(): void;
  notifications: notificationsAttributes[];
  setNotifications: React.Dispatch<
    React.SetStateAction<notificationsAttributes[]>
  >;
  updateUser(): Promise<void>;
  remmemberMe: boolean;
  markAsRead: (data: any) => Promise<void>;
  setRemmemberMe: React.Dispatch<React.SetStateAction<boolean>>;
  remmemberCredentials: credentialsProps;
}

const ApiContext = createContext<ApiContextData>({} as ApiContextData);

export const ApiProvider: React.FC = ({ children }) => {
  const { notify, notifyOnly, changeFranchiseGlobal, setTheme } = useGlobal();

  const [notifications, setNotifications] = useState<notificationsAttributes[]>(
    []
  );
  const [remmemberMe, setRemmemberMe] = useState(() => {
    const rem = localStorage.getItem("@MAX:remmember");
    return !!rem;
  });
  const [remmemberCredentials, setRemmemberCredentials] = useState(() => {
    const rem = localStorage.getItem("@MAX:remmember");
    return rem ? { ...JSON.parse(rem) } : {};
  });
  const [data, setData] = useState<DataProps>(() => {
    const token = localStorage.getItem("@MAX:token");
    const user = localStorage.getItem("@MAX:user");
    if (token && user) return { token, user: JSON.parse(user) };
    return {} as DataProps;
  });
  const navigate = useNavigate();

  const api = axios.create({ baseURL: process.env.REACT_APP_API });

  api.interceptors.request.use(async (config: any) => {
    const token = localStorage.getItem("@MAX:token");
    const franchise_json = localStorage.getItem("@MAX:franchise");
    const franchise_obj = JSON.parse(franchise_json || "{}");
    const franchise_id = franchise_obj?.id;
    const url = config.url;
    const query = url.split("?")[1];

    if (!url.includes("franchise_id")) {
      if (query) {
        if (url?.split("")?.pop() === "&")
          config.url = `${url}franchise_id=${franchise_id}`;
        else config.url = `${url}&franchise_id=${franchise_id}`;
      } else if (url.includes("?"))
        config.url = `${url}franchise_id=${franchise_id}`;
      else config.url = `${url}?franchise_id=${franchise_id}`;
    }

    config.headers.authorization = `Bearer ${token}`;

    return config;
  });

  api.interceptors.response.use(
    function (response) {
      return response;
    },
    function (error) {
      if (error.response.status === 401) {
        if (error.response.data.path === "plan-not-have-permition") {
          const entity = error.response.data.entity;
          const module = modules.find((m) => m.name === entity)?.description;
          notifyOnly(
            `O plano de assinatura da sua imobiliária não permite acesso ao módulo "${module}"`,
            "alert"
          );
          navigate("/billing/subscription");
        } else {
          notifyOnly("Sua sessão expirou, faça login novamente", "alert");
          signOut();
        }
      }
      if (error.response.status === 402) {
        const correct_situations: any = {
          "not-have-subscription":
            "Imobiliária ainda não possui uma assinatura!",
          "not-have-transactions":
            "Assinatura da imobiliária ainda não possui cobranças geradas!",
          "subscription-canceled": "Assinatura da imobiliária está cancelada!",
          "subscription-expired": "Assinatura da imobiliária está expirada!",
          "subscription-not-paid":
            "Assinatura da imobiliária está suspensa por falta de pagamento!",
          "subscription-waiting-block":
            "Assinatura da imobiliária está aguardando pagamento para a ativação!",
        };

        if (correct_situations[error.response.data.path]) {
          notifyOnly(correct_situations[error.response.data.path], "alert");
        } else {
          notifyOnly(
            "Algum problema relacionado a assinatura da imobiliária! Entre em contato conosco!",
            "alert"
          );
        }

        navigate("/billing/subscription");
      }
      if (
        error.response.status === 400 &&
        error.response.data.path === "balance"
      ) {
        notifyOnly(
          "Você não tem saldo suficiente para realizar essa operação!",
          "alert"
        );
      }
      return Promise.reject(error);
    }
  );

  const searchPropertiesTypes = async (props: ISearchProps) => {
    const string = setUpConsultation(props);

    try {
      const result = await api.get(
        `/generic_searches/properties-types?${string}`
      );
      const formatted = result.data.rows.map((item: any) => ({
        label: item.name,
        value: item.id,
        this: item,
      }));

      const data = result.data.rows;
      return { data, formatted };
    } catch (err) {
      return { data: [], formatted: [] };
    }
  };

  const searchPersons = async (props: ISearchPersonsProps) => {
    const string = setUpConsultation(props);

    try {
      const result = await api.get(`/generic_searches/persons?${string}`);
      const formatted = result.data.rows.map((item: any) => ({
        label: getPersonLabel({ person: item }),
        value: item.id,
        this: item,
      }));

      const data = result.data.rows;
      return { data, formatted };
    } catch (err) {
      return { data: [], formatted: [] };
    }
  };

  const searchAnalysts = async (props: ISearchPersonsProps) => {
    const string = setUpConsultation(props);

    try {
      const result = await api.get(`/generic_searches/analysts?${string}`);
      const formatted = result.data.rows.map((item: any) => ({
        label: getPersonLabel({ person: item }),
        value: item.id,
        this: item,
      }));

      const data = result.data.rows;
      return { data, formatted };
    } catch (err) {
      return { data: [], formatted: [] };
    }
  };

  const searchConsults = async (props: ISearchConsultsProps) => {
    const string = setUpConsultation(props);

    try {
      const result = await api.get(`/generic_searches/queries?${string}`);

      result.data.rows = result.data.rows.filter((item: any) => {
        let result_biro = item?.result_biro;
        if (typeof result_biro === "string")
          result_biro = JSON.parse(result_biro) as ResultBiroProps;
        const type = item.type;

        return !(
          result_biro.ErrorMessage ||
          (type === "PJ" &&
            result_biro.BestInfo.CompanyStatus === "DESCONHECIDO")
        );
      });

      const formatted = result.data.rows.map((item: any) => {
        const label = getConsultLabel({ consult: item });
        return { label, value: item.id, this: item };
      });

      const data = result.data.rows;
      return { data, formatted };
    } catch (err) {
      return { data: [], formatted: [] };
    }
  };

  const searchFranchises = async (props: ISearchProps) => {
    const string = setUpConsultation(props);

    try {
      const result = await api.get(`/generic_searches/franchises?${string}`);
      const formatted = result.data.rows.map((item: any) => ({
        label: getFranchiseLabel({ franchise: item }),
        value: item.id,
        this: item,
      }));

      const data = result.data.rows;
      return { data, formatted };
    } catch (err) {
      return { data: [], formatted: [] };
    }
  };

  const searchState = async (props: ISearchProps) => {
    const string = setUpConsultation(props);

    try {
      const result = await api.get(`/generic_searches/states?${string}`);
      const formatted = result.data.rows.map((item: any) => ({
        label: item.name,
        value: item.id,
        initials: item.initials,
        this: item,
      }));

      const data = result.data.rows;
      return { data, formatted };
    } catch (err) {
      return { data: [], formatted: [] };
    }
  };

  const searchCities = async (props: ISearchCitiesProps) => {
    const string = setUpConsultation(props);

    try {
      if (!props.state_id) return { data: [], formatted: [] };
      const result = await api.get(`/generic_searches/cities?${string}`);
      const formatted = result.data.rows.map((item: any) => ({
        label: item.name,
        value: item.id,
        this: item,
      }));

      const data = result.data.rows;
      return { data, formatted };
    } catch (err) {
      return { data: [], formatted: [] };
    }
  };

  const searchAddress = useCallback(async (props: ISearchProps) => {
    const { search } = props;

    try {
      const result = await api.get(`/address/${search}`);

      const address = result.data.address;

      const city: cityProps = result.data.city;

      const state: stateProps = result.data.state;

      return { address, city, state };
    } catch (err) {
      return {};
    }
  }, []);

  const searchDocumentsTypes = useCallback(
    async (props: ISearchDocumentsTypesProps) => {
      const string = setUpConsultation({ ...props, limit: 50 });

      try {
        const result = await api.get(
          `/generic_searches/documents-types?${string}`
        );

        const formatted = result.data.rows.map((item: any) => {
          return { label: item.name, value: item.id, this: item };
        });

        const data = result.data.rows;

        return { data, formatted };
      } catch (err) {
        return { data: [], formatted: [] };
      }
    },
    []
  );

  const searchSignatories = useCallback(
    async (props: ISearchSignatoriesProps) => {
      const { proposal_id, property_id } = props;

      try {
        const _type = proposal_id ? "proposals" : "properties";

        const _id = proposal_id ? proposal_id : property_id;

        const url = `/${_type}/signatories/${_id}`;

        const { data } = await api.get(url);

        return data;
      } catch (error) {
        return [];
      }
    },
    []
  );

  const searchContractModels = useCallback(async (props: ISearchProps) => {
    const string = setUpConsultation({ ...props, limit: 50 });

    try {
      const result = await api.get(
        `/generic_searches/contract-models?${string}`
      );

      const formatted = result.data.rows.map((item: any) => {
        return { label: item.name, value: item.id, this: item };
      });

      const data = result.data.rows;

      return { data, formatted };
    } catch (err) {
      return { data: [], formatted: [] };
    }
  }, []);

  const searchProperties = useCallback(async (props: ISearchProps) => {
    try {
      const obj: any = { page: 1, limit: 20, ...props };

      const string = setUpConsultation(obj);

      const result = await api.get(`/generic_searches/properties?${string}`);

      const formatted = result.data.rows.map((item: any) => {
        return {
          label: getPropertyLabel({ property: item }),
          value: item.id,
          this: item,
        };
      });

      const data = result.data.rows;

      return { data, formatted };
    } catch (err) {
      return { data: [], formatted: [] };
    }
  }, []);

  const searchProposals = useCallback(async (props: ISearchProposalsProps) => {
    try {
      const obj: any = { page: 1, limit: 20, ...props };

      const string = setUpConsultation(obj);

      const result = await api.get(`/generic_searches/proposals?${string}`);

      const formatted = result.data.rows.map((item: any) => {
        return {
          label: getProposalLabel({ proposal: item }),
          value: item.id,
          this: item,
        };
      });

      const data = result.data.rows;

      return { data, formatted };
    } catch (err) {
      return { data: [], formatted: [] };
    }
  }, []);

  const consultsServices: IConsultsServicesProps = {
    property_type_id: searchPropertiesTypes,
    consults: searchConsults,
    person_id: searchPersons,
    analyst_id: searchAnalysts,
    franchise_id: searchFranchises,
    state_id: searchState,
    city_id: searchCities,
    address: searchAddress,
    document_type_id: searchDocumentsTypes,
    signatories: searchSignatories,
    contract_model_id: searchContractModels,
    property_id: searchProperties,
    proposal_id: searchProposals,
    default_id: async () => {
      return { data: [], formatted: [] };
    },
  };

  const searchNotifications = useCallback(async () => {
    try {
      const result = await api.get("/notifications");
      setNotifications([...result.data]);
    } catch (err) {
      notify("Erro ao buscar notificações", "error");
      console.log(err);
    }
  }, []);

  const markAsRead = useCallback(
    async ({ user_id, property_id, proposal_id, contract_id, type }) => {
      await api.post("/notifications/maskAsRead", {
        user_id,
        property_id,
        proposal_id,
        contract_id,
        type,
      });
      await searchNotifications();
    },
    []
  );

  const signIn = useCallback(
    async ({ email, password }, remmemberMe = false) => {
      changeFranchiseGlobal({} as franchiseProps);
      const res = await api.post("/login", { email, password });
      const { token, user } = res.data;
      if (
        [
          "root",
          "broker",
          "responsible",
          "customer",
          "analyst",
          "manager",
        ].includes(user.type)
      ) {
        if (remmemberMe) {
          localStorage.setItem(
            "@MAX:remmember",
            JSON.stringify({ email, password })
          );
          setRemmemberCredentials({ email, password });
        } else {
          localStorage.removeItem("@MAX:remmember");
          setRemmemberCredentials({ email: "", password: "" });
        }

        localStorage.setItem("@MAX:token", token);
        localStorage.setItem("@MAX:user", JSON.stringify(user));
        setData({ token, user });
        window.location.reload();
      } else notify("Você não tem permissão para acessar!", "alert");
    },
    []
  );

  const signOut = useCallback(() => {
    changeFranchiseGlobal({} as franchiseProps);
    localStorage.removeItem("@MAX:theme");
    localStorage.removeItem("@MAX:token");
    localStorage.removeItem("@MAX:user");
    localStorage.removeItem("@MAX:franchise");
    setTheme("light");
    setData({} as DataProps);
    changeFranchiseGlobal({} as franchiseProps);
  }, []);

  const updateUser = useCallback(async () => {
    try {
      const result = await api.get("/users/my-infos");
      const new_user = result.data;
      localStorage.setItem("@MAX:user", JSON.stringify(new_user));
      setData((atualData) => {
        return { token: atualData.token, user: new_user };
      });
    } catch (err) {
      console.log(err);
    }
  }, []);

  useEffect(() => {
    const { user } = data;
    const type = user?.type;
    const franchise_id = user?.person?.franchise_id;
    if (["analyst", "broker", "manager"].includes(type) && franchise_id)
      changeFranchiseGlobal({ id: franchise_id } as franchiseProps);
  }, [data]);

  return (
    <ApiContext.Provider
      value={{
        signIn,
        markAsRead,
        remmemberCredentials,
        remmemberMe,
        setRemmemberMe,
        updateUser,
        notifications,
        setNotifications,
        signOut,
        user: data.user,
        token: data.token,
        api,
        consultsServices,
      }}
    >
      {children}
    </ApiContext.Provider>
  );
};

export function useApi(): ApiContextData {
  const context = useContext(ApiContext);
  if (!context) {
    throw new Error("useApi must be used within an ApiProvider");
  }
  return context;
}
